<template>
    <div class="h-screen--menu">
        <nav class="sticky top-0 z-10 bg-white shadow">
            <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div class="relative flex h-16 justify-between">
                    <div class="flex flex-1 items-center">
                        <img class="h-8 w-auto" src="../../images/clawmee-logo.svg" />
                    </div>
                </div>
            </div>
        </nav>

        <div class="flex h-full items-center bg-gray-100 dark:bg-slate-900">
            <slot />
        </div>
    </div>
</template>
